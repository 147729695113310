.auth-header
{
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
}

.auth-form
{
  width: 75%;
}

@media (max-width: 990px) {
  .auth-form
  {
    width: 100%;
  }
}
