/* body {
	background: #ccc;
}
 */
.main-section {

	margin: 0px 30px;
	/* height: 304px; */
}

.content-box-1 {
	margin: 20px 0px;
}

.content-box-1 p {
	margin: 7px 0px;
}

.content-box-2 p {
	margin: 7px 0px;
}

.button-css {
	background: #ff5d17;
	padding: 15px 30px;
	color: #fff;
	border: none;
	border-radius: 100px;
	margin-top: 30px;
}

.button-css a {
	text-decoration: none;
	color: #fff;
}

.box-left {
	text-align: center;
	width: 50%;
	float: left;
	padding: 3% 0;
}

.box-left img
{
	height: 231px;
}

.box-right {
	width: 44%;
	padding: 2% 3%;
	float: left;
}

.box-title {
	color: #ff5d17;
	margin: 0px;
}

.button-css span
{
	font-size: 16px;
    font-weight: bold;
}

/* The container */
.container_lbl {
     position: relative;
    padding-left: 28px;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-right: 30px;
}

/* Hide the browser's default radio button */
.container_lbl input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container_lbl:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container_lbl input:checked ~ .checkmark {
  background-color: #ff5d17;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container_lbl input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container_lbl .checkmark:after {
 	    top: 5px;
    left: 5px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: white;
}

.badg_6{
    /* background-image: url(https://localhost:3000/static/media/login-backgroup.197922a….png); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.left_one{
    border: 2px solid #d2d2d2  !important;
    padding-top: 22px;
    height: 35%;
}

.right_one{
    /* border: 2px solid #000 !important;
    margin-left: 3%; */
    padding-left:3%;
    padding-right:3%;
}


.img-right{
    margin-left: -10%;
}




.card{
    margin-bottom: 5%;
}

.hidden{
    display: none;
}
