.certificate-holder {
  max-width: 800px;
}

.audit-request-form
{
  .certificate-card {
    height: auto !important;
    width: auto !important;
  }
}

.certificate-card {
  background-color: #fff;
  height: 1160px;
  width: 800px;

  .certificate-scores {
    background-color: #ffffff;

    .section-score {
      font-size: 12px;
    }

    .section-score-title {
      font-size: 12px;
      font-weight: 600;
    }
  }

  .certificate-logo {
    width: 100px;
  }

  p {
    font-size: 13px;
  }

  .certificate-information {
    p {
      margin-bottom: 5px;
    }
  }

  .certificate-signature {
    height: 50px;
  }

  .certificate-footer-section {
    .fra-link {
      font-size: 16px;
    }
  }
}

.request-sent {
  .card {
    background-color: #F8F9FA;
  }
}

.audit-request-form {
  textarea {
    min-height: 150px;
  }
}

.full-report-container {
  max-width: 750px;

  .report-answers {
    color: #6C757D;
  }
}

.status-field {
  white-space: nowrap;
}


.question-heading
{
  color: $primary !important;
}

.question-mark
{
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-color: $primary;
  color: $white;
  font-size: 12px;
  padding: 2px;
  border-radius: 10px;
  text-align: center;
  font-weight: 700;
}
.template-featured-image
{
  height: 275px;
  object-fit: cover;
}

.sections-list-panel
{
  position: -webkit-sticky;
  position: sticky;
  top: 75px;
}

.section-form
{
  max-width: 730px;
  > .card-body {
    //overflow: hidden;
    //overflow-y: auto;
    //max-height: calc(100vh - 300px) !important;

    &.client
    {
      //max-height: calc(100vh - 230px) !important;
    }
  }
  h6{

  }
}

.assessment-section-container
{
  overflow: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 160px) !important;
}


@media (max-width: 991px) {
  .assessment-section-container
  {
    max-height: 100% !important;
    overflow: auto !important;
  }
  .section-form
  {
    > .card-body {
      max-height: 100% !important;
      overflow: auto !important;
    }
  }
}

.mlp-7{
    margin-left: 7%;
}

.sd-accordion-item .accordion-button{
    background-color: #808080 !important;
    color:#fff !important;
    text-align: center;
}

.sd-radio{
     margin-left: 0%;
}

.sd-container .accordion-item
{
    margin: 1px 0 !important;
    border: 0 !important;
}

.sd-container .card-body{
    padding:0px;
}


.likes__wrapper{
    display:flex;
    align-content:center;
    justify-content:center;

  }
  .likes__relavance{
    position:relative;
    padding:0 80px;
  }
  .likes__list{
    position:absolute;
    box-sizing: border-box;
    overflow-y:scroll;
    max-height:150px;
    left:0%;
    z-index:999;
    background:white;
    padding:10px;
    border:1px solid grey;
    box-shadow: 0 0 2px 2px grey;
  }
  .page-break {
    page-break-before: always !important;
}
/* Certificate */
.custom-table {
    border-collapse: collapse;
    width: 100%;
}

.custom-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
}

/* Make every 3rd td go to the next row */
.custom-table tr:nth-child(6n+4) td,
.custom-table tr:nth-child(6n+5) td {
    clear: both;
    display: block;
    width: 100%;
}

.td-css-custome
{
    width: 33.33% !important;
    float: left !important;
}


.custome-alignment
{
    display: flex;
    justify-content: end;
}


/*  Progress bar certificate */
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0
}

.progress {
    width: 40px;
    height: 40px;
    font-size: 14px;
    color: #000;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    background: #fff;
    text-align: center;
    line-height: 40px;
    margin: 0px;
    font-weight: bold;
    justify-content: center;
}

.progress .title {position: relative;z-index: 100;}

.progress .overlay {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #fff;
}

.progress .left, .progress .right {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 4px solid rgba(40,167,69,0.85);
    border-radius: 100px 0px 0px 100px;
    border-right: 0;
    transform-origin: right;
}


.progress .left {animation: load1 1s linear forwards;}

.progress:nth-of-type(2) .right, .progress:nth-of-type(3) .right {animation: load2 .5s linear forwards 1s;}

.progress:last-of-type .right, .progress:first-of-type .right {animation: load3 .8s linear forwards 1s;}

@keyframes load1 {
    0% {transform: rotate(0deg);}

    100% {transform: rotate(180deg);}
}

@keyframes load3 {
    0% {z-index: 100;transform: rotate(180deg);}

    100% {
        z-index: 100;transform: rotate(360deg);}
}


.table > :not(caption) > * > * {
    border-bottom-width: 0px !important;
}


/*supporting doc  */
.sd-radio-container {
    display: flex;
    align-items: center;
  }

  .sd-radio-label {
     width: 80%;
    /* white-space: nowrap; */
    overflow: hidden;
    /* text-overflow: ellipsis;  */
    margin-right: 0px;
  }

  .sd-radio-icon {
    width: 24px;
    cursor: pointer;
  }


  .edit_recomm_icon{
    padding: 2px 5px;
    margin-right: 5px;
    display: table-cell;
  }
