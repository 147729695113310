.report-container {
    .rating-improvement-statement
    {
        font-size: 12px;
    }
    .section-score-title {
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
    }

    .section-score {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
    }

    .score-title-indicator
    {
        width: 16px;
        height: 16px;
        margin-right: 15px;
    }

    .accordion-item
    {
        margin: 15px 0 !important;
        border: 0 !important;
    }

    .accordion-button:not(.collapsed) {
        color: $body-color;
        background-color: $white;
        box-shadow: none !important;
    }
    .accordion-button
    {
        font-weight: 600;
    }
}

.text-high {
    color: $danger;
}

.text-medium {
    color: #E67F23;
}

.text-low {
    color: $warning;
}

.text-no {
    color: $success;
}

.bg-high {
    background: $danger;
}

.bg-medium {
    background: #E67F23;
}

.bg-low {
    background: $warning;
}

.bg-no {
    background: $success;
}


.text-high-competency {
    color: $success;
}

.text-medium-competency {
    color: $warning;
}

.text-low-competency {
    color: $danger;
}

.text-no-competency {
    color: $danger;
}



.bg-high-competency {
    background: $success;
}

.bg-medium-competency {
    background: $warning;
}

.bg-low-competency {
    background: $danger;
}

.bg-no-competency {
    background: $success;
}

.score-box{
    padding: 10px;
    border-radius: 5px;
    color: #fff !important;
    width: 100%;
}


/* NEW Full report UI */
.card-fr{
    margin-bottom: 0px !important;
}
.m-t-4{
    margin-top: 1.5rem !important;
}
.title-bg
{
    background:#666666 !important;
}

.title-bg h4
{
    font-weight:600;
    color:#fff;
}

.bg-tabel-details
{
    background:#efefef !important;
}

.height-border
{
    min-height: 40px;
    border: 1px solid #666666 !important;
    height: 40px !important;
    text-align: left;
}


#full-report .table {
    width: 100%;
    max-width: 100%;
    font-size: 1rem;
    margin-bottom: 20px;
    border: 1px solid #666666;
}
.table > thead {
    vertical-align: bottom;
}
h4 {
    font-size: 1.5rem;
    line-height: 1.2;
    /* color: #fff; */
}
table {
    background-color: transparent;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd;
}
td, th {
    padding: 0;
}


.title-bg h5, .title-bg .h5 {
    font-weight: 600;
    color: #fff;
}

h5, .h5 {
    font-size: 1.5rem;
    line-height: 1.2;
}

/* NEW Full report UI END */

.sd-div{
    margin-left: 3em;
    text-align: justify;

}
.pd-left{
    padding-left: 2em !important;
}

.wi-100
{
    width: 40em !important;
    max-width: 40em !important;
}



.watermark_header{
    /* background-image: url("../../../../public/assets/images/headeroriginal.png"); */
    background-image: url("../../images/headeroriginal.png");
      background-repeat:no-repeat;
    width:100%;
    background-position:0px 0px;
    margin-top:-25px;
    font-family:arial,sans-serif;
    position:absolute;
    /* margin-left:-25px;
    padding-left:15px;
    left:-20px; */
    min-height:121px;
}
.mt-80{
    margin-top: -80px;
}


.fr-progress {
    /* width: 70px;
    height: 70px;
    min-height: 70px;
    max-height: 70px;
    font-size: 18px;
    color: #000;
    line-height: 60px;
    border-radius: 50%;
    overflow: hidden;

    text-align: center;
    vertical-align: middle;
    margin: 0px;
    font-weight: bold; */
}
/* .progress {
    width: 70px !important;
    height: 70px !important;
    min-height: 70px;
    max-height: 70px;
    font-size: 18px;
    color: #000;
    line-height: 60px !important;
    border-radius: 50%;
    overflow: hidden;
    position: relative !important;
    background: #fff;
    border: 7px solid #ffc107;
    text-align: center;

} */

.centered-progress {
    /* display: flex;
    justify-content: center;
    align-items: center;
    height: 100% ;*/
    margin-left:40%;
}
/*

.border-black, .border-black .left,.border-black .right{
    border-top: 5px solid #262626 !important;
    border-right: 0px;
    border-bottom: 5px solid #262626 !important;
    border-left: 5px solid #262626 !important;
    border-image: initial;
}


.border-green .left,.border-green .right{
    border-top: 5px solid #28A745 !important;
    border-right: 0px;
    border-bottom: 5px solid #28A745 !important;
    border-left: 5px solid #28A745 !important;
    border-image: initial;
}

.border-yellow, .border-yellow .left,.border-yellow .right{
    border-top: 5px solid #FFC107;
    border-right: 0px;
    border-bottom: 5px solid #FFC107;
    border-left: 5px solid #FFC107;
    border-image: initial;
}


.border-orange, .border-orange .left,.border-orange .right{
    border-top: 5px solid #E67F23 !important;
    border-right: 0px !important;
    border-bottom: 5px solid #E67F23 !important;
    border-left: 5px solid #E67F23 !important;
    border-image: initial;
}



.border-red .left,.border-red .right{
    border-top: 5px solid #DC3545 !important;
    border-right: 0px !important;
    border-bottom: 5px solid #DC3545 !important;
    border-left: 5px solid #DC3545 !important;
    border-image: initial;
}



.progress-black{
    border: 4px solid #262626 !important;
}

.progress-green{
    border: 4px solid #28A745 !important;
}

.border-yellow{
    border: 4px solid #FFC107 !important;
}


.border-orange{
    border: 4px solid #E67F23 !important;
}

.progress-red{
    border: 4px solid #DC3545 !important;
} */

.bg-recommendation{
    background-color: #FFE2E2 !important;
}
