#competency-certificate-container {
  .competency-certificate {
    width: 1188px;
    height: 841px;

    .certificate-background {
      width: 1188px;
      height: 841px;
    }
    .name {
      position: relative;
      top: 295px;
      font-size: 75px;
    }
    .date {
      position: relative;
      top: 400px;
      font-size: 19px;
    }
    .assessment-score {
      position: absolute;
      bottom: 190px;
      right: 200px;

      .assessment-score-icon {
        height: 100px !important;
        width: 100px !important;
        .score-icon {
          height: 100px !important;
          width: 100px !important;
          svg {
            height: 100px;
            width: 100px;
          }
        }

        span {
          font-size: 25px !important;
        }
      }
    }
  }
}

.competency-certificate {
  width: 630px;
  height: 446px;
  position: absolute;
  left: 0;
  right: 0;

  .certificate-background
  {
    width: 630px;
    height: 446px;
    position: absolute;
    left: 0;
    top:0;
  }

  .name {
    position: relative;
    top: 150px;
    color: #202428;
    font-size: 45px;
    font-weight: bold;
  }

  .date {
    position: relative;
    top: 205px;
    color: #636262;
    font-size: 12px;
    font-weight: 600;
  }

  .assessment-score {
    position: absolute;
    bottom: 105px;
    right: 115px;
    .assessment-score-icon {
      height: 60px !important;
      width: 60px !important;
      .score-icon {
        height: 60px !important;
        width: 60px !important;
        svg {
          height: 60px;
          width: 60px;
        }
      }

      span {
        font-size: 12px !important;
      }
    }
  }
}
