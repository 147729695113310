$sidebar-bg-color: #212529;
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: rgba(#0c1e35, 0.95);
$sidebar-color: #212529;
$highlight-color: #ECECEC;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 35px;

@import '~react-pro-sidebar/dist/scss/styles';
body {
    margin: 0;
    height: 100vh;
}

#root {
    height: 100%;
}

.app {
    height: 100%;
    display: flex;
    position: relative;

    .btn-toggle {
        cursor: pointer;
        width: 35px;
        height: 35px;
        color: #fff;
        text-align: center;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        display: none;
    }

    .sidebar-btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        .sidebar-btn {
            transition: width 0.3s;
            width: 150px;
            padding: 1px 15px;
            border-radius: 40px;
            background: rgba(255, 255, 255, 0.05);
            color: #adadad;
            text-decoration: none;
            margin: 0 auto;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-overflow: ellipsis;
            overflow: hidden;

            span {
                margin-left: 5px;
                font-size: 13px;
            }

            &:hover {
                color: $highlight-color;
            }
        }
    }

    .collapsed {
        .sidebar-btn {
            display: inline-block;
            line-height: 40px;
            padding: 0;
        }
    }

    main {
        padding: 0;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .block {
        display: flex;
        margin-bottom: 24px;
        font-size: 14px;
        color: #545454;

        > span {
            margin-left: 10px;
        }
    }
}

@media (max-width: 992px) {
    .app .btn-toggle {
        display: flex !important;
    }
}

.pro-sidebar {
    top: 67px;
    height: calc(100vh - 67px) !important;
    min-height: 400px !important;

    .pro-menu {
        margin: 0 12px !important;

        .pro-menu-item {
            border-radius: 8px !important;
            margin: 12px 0 !important;

            > .pro-inner-item {
                padding: 8px 15px 8px 20px !important;
            }
        }
    }
    .content-footer
    {
        font-weight: 400;
        font-size: 12.8px;
        line-height: 19px;
    }

    .active-link {
        color: var(--bs-primary) !important;

        &:hover {
            color: var(--bs-primary) !important;
        }

        a {
            color: white;

            &:hover {
                color: var(--bs-primary);
            }
        }

        &.pro-menu-item > {
            .pro-inner-item {
                &:hover {
                    color: var(--bs-primary);
                }
            }
        }
    }

    .sidebar-content {
        height: 100%;

        .list-group {
            background: none !important;

            .list-group-item {
                padding: 12px;
                margin: 5px 0;
                background-color: #2A2E31;
            }
        }
    }
}

