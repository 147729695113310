.fra-table {
  overflow: hidden;
  overflow-x: auto;

  .react-bootstrap-table {
    background-color: $white !important;
  }

  th {
    white-space: nowrap !important;
  }
}

.table-links {
  color: $primary !important;
  text-decoration: underline !important;
}