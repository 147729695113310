a {
    color: var(--bs-body-color);
    text-decoration: none !important;

    &:not(.btn) {
        &:hover {
            color: $primary !important;
        }
    }
}

.btn {
    justify-content: center;
    display: flex;
    align-items: center;
}
.btn-black {
    background-color: $black !important;
}

.text-deleted{
    text-decoration: Line-through;
}

.bg-none
{
    background: transparent !important;
}

.text-rating-orange {
    color: #E67F23 !important;
}

.inactive-btn {
    color: var(--bs-btn-disabled-color);
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity);
}

.question-container {
    border-radius: 0.375rem;
    border: 1px solid #CED4DA;
}

.counter-space {
    background-color: #DEE2E6;

    &.choices {
        border-radius: 0.375rem;
    }
}

.tox-tinymce {
    border: 1px solid #ced4da !important;
    border-radius: 0.375rem !important;
}

.is-invalid {
    .tox-tinymce {
        border: 1px solid #dc3545;
    }
}

.repeatable-questions-label {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #6C757D;
}

.react-select {
    &.invalid > {
        div {

            border: 1px solid $danger;
            border-radius: 0.375rem;
        }
    }
}

.nowrap
{
    white-space: nowrap !important;
}



.external-iframe{
    /* position: fixed;
    inset: 0px;
    width: 80%;
    height: 95%;
    margin-top: 5%;
    margin-left: 15%; */
    height: 100%;
    inset: 0;
    margin-left: 15%;
    margin-top: 5%;
    position: absolute;
    width: 80%;
}
/* Iframe Form Start */
@media only screen and (max-width: 600px) {

	.external-iframe
	{
		position: fixed;
		inset: 0px;
		width: 100%;
		height: 95%;
		margin-top: 5%;
		margin-left: 0%;
	}

	#myform {
		padding: 5px !important;
		max-height: 700px;
		overflow-y: hidden;
		overflow-x: hidden;
	}

	#wrapper {
		width: 30%;
		display: inline-block;
		vertical-align: top;
		margin-right: 10px !important;
	}

	/* #myreport {
		width: 67% !important;
		display: inline-block;
		vertical-align: top;
		padding: 15px !important;
	} */
    #myreport {
        width: 64%  !important;
        display: inline-block;
        vertical-align: top;
        padding: 20px  !important;
        overflow-y: scroll;
        height: 100%;
    }

	.form-wrapper {
		position: relative;
		top: 0px;
		left: 0px;
		z-index: 9999;
		width: 100% !important;
	}

	#form-add {
		width: 100% !important;
		padding-left: 0px !important;
	}


	}
/* IFrame form end */


.certificate-holder {
    max-width: 1400px  !important;
}
.certificate-card {
    height: 1160px !important;
    width: 1400px !important;
}
.external-iframe-cert {
    height: 100% !important;
    inset: 0 !important;
    margin-left: 5% !important;
    margin-top: 5% !important;
    position: absolute !important;
    width: 91% !important;
}


/*  Suport link */

.betterdocs-ia-launcher-wrapper .betterdocs-ia-launcher, .betterdocs-ia-common-header, .betterdocs-ia-common-header .betterdocs-ia-search .betterdocs-ia-search-icon, .betterdocs-ia-tab-message-container .betterdocs-ia-feedback-form .betterdocs-ia-submit button, .betterdocs-ia-single-docs-wrapper .betterdocs-ia-singleDoc-footer .betterdocs-ia-footer-feedback{background-color:#0066ff;}
        .betterdocs-ia-single-docs-wrapper .betterdocs-ia-singleDoc-header , .betterdocs-ia-tabs .active p{color:#0066ff;}
        .betterdocs-ia-single-docs-wrapper .betterdocs-ia-singleDoc-header .content-icon-expand svg path, .betterdocs-ia-docs-content .content-icon svg path, .betterdocs-ia-single-docs-wrapper .betterdocs-ia-singleDoc-header .content-icon-back svg path{fill:#0066ff;}
        .betterdocs-ia-tabs li.active svg g path{fill:#0066ff;}
        .betterdocs-ia-launcher-wrapper .betterdocs-ia-launcher:hover{background-color:#0066ff;}
        .betterdocs-ia-common-header .betterdocs-title:not(:last-child){color:#fff;}
        .betterdocs-ia-common-header h2{color:#fff;}
        .betterdocs-ia-common-header .betterdocs-info{color:#fff;}
        .betterdocs-ia-docs .betterdocs-ia-docs-heading .doc-title{color:#111213;}
        .betterdocs-ia-docs .betterdocs-ia-docs-heading{background-color:#fff;}
        .betterdocs-ia-docs-content .content-item h4{color:#111213;}
        .betterdocs-ia-docs-content .content-item p{color:#6D7175;}
        .betterdocs-ia-docs-content{background-color:#fff;}
        .betterdocs-ia-common-header .betterdocs-ia-search, .betterdocs-ia-common-header .betterdocs-ia-search .betterdocs-ia-search-field{background-color:#fff;}
        .betterdocs-ia-common-header .betterdocs-ia-search .betterdocs-ia-search-field::placeholder{color:#1c1c1c;}
        .betterdocs-ia-common-header .betterdocs-ia-search .betterdocs-ia-search-field{color:#000000;}
        .betterdocs-ia-common-header .betterdocs-ia-search .betterdocs-ia-search-icon svg{fill:#FFFFFF;}
        .betterdocs-ia-tabs{background-color:#FFF;}
        .betterdocs-ia-tabs li p{color:#202223;}
        .betterdocs-ia-tab-message-container .message__header .header__content h4{color:#fff;}
        .betterdocs-ia-tab-message-container .message__header .header__content p{color:#fff;}
        .betterdocs-ia-tab-message-container .betterdocs-ia-feedback-form .betterdocs-ia-group .ia-input, .betterdocs-ia-tab-message-container .betterdocs-ia-feedback-form .betterdocs-ia-group > textarea{background-color:#fff;}
        .betterdocs-ia-tab-message-container .betterdocs-ia-feedback-form .betterdocs-ia-email-group p, .betterdocs-ia-tab-message-container .betterdocs-ia-feedback-form .betterdocs-ia-name-group p, .betterdocs-ia-tab-message-container .betterdocs-ia-feedback-form .betterdocs-ia-subject-group p, .betterdocs-ia-tab-message-container .betterdocs-ia-feedback-form .betterdocs-ia-message-group p{color:#202223;}
        .betterdocs-ia-tab-message-container .betterdocs-ia-feedback-form .betterdocs-ia-attachments-group button{background-color:#fff;}
        .betterdocs-ia-tab-message-container .betterdocs-ia-feedback-form .betterdocs-ia-attachments-group p{color:#6d7175;}
        .betterdocs-ia-single-docs-wrapper .betterdocs-ia-singleDoc-content .doc-title{color:#111213;}
        .betterdocs-ia-single-docs-wrapper .betterdocs-ia-singleDoc-header.on-scroll h2{color:#111213;}
        .betterdocs-ia-single-docs-wrapper .betterdocs-ia-singleDoc-header{background-color:#F6F6F7;}
        .betterdocs-ia-single-docs-wrapper .betterdocs-ia-singleDoc-header .content-icon-back, .betterdocs-ia-single-docs-wrapper .betterdocs-ia-singleDoc-header .content-icon-expand{background-color:#f6f6f7;}
        .betterdocs-ia-single-docs-wrapper .betterdocs-ia-singleDoc-footer .betterdocs-ia-footer-feedback .betterdocs-ia-reaction-group .ia-reaction{background-color:#00A375;}
        .betterdocs-ia-single-docs-wrapper .betterdocs-ia-singleDoc-footer .betterdocs-ia-footer-feedback .betterdocs-ia-reaction-group .ia-reaction .betterdocs-emo{fill:#ffffff;}
        .betterdocs-ia-single-docs-wrapper .betterdocs-ia-singleDoc-footer .betterdocs-ia-footer-feedback p{color:#FAFAFA;}

