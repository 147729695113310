.user-info
{
    background-color: #E9ECEF;
}
.tab-button {
    padding: 10px 20px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 16px;
    color: #555;
    font-weight: bold;
    transition: color 0.3s, background-color 0.3s;
    background-color: #f0f0f0;
    color: #333;
    font-weight: bold;
    margin-left: 2px;
  }

  .tab-button.active {
    background-color: #f0f0f0;
    color: #333;
    font-weight: bold;
    border-bottom: 3px solid #ff753a;
  }

  .tab-button:hover {
    color: #ff753a;
  }

  .tab-container {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #ccc;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }

  .tab-content {
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
