.choice-score
{
  width: 75%;
}
.choice-answer
{
  width: 100%;
}

.question-drag
{
  position: absolute;
  top: 10px;
  right: 30px;
}
.expand-collapse{
    position: absolute;
    top: 12px;
    right: 10px;
}
@media (max-width: 991px) {
  .choice-score
  {
    width: 100%;
  }
}


