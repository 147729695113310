:not(.btn-check) {
  + .btn:hover {

  }
}

.btn:first-child {
  &.btn-outline-primary {
    &:hover {
      color: $white !important;
    }
  }
}

.btn-success {
  color: $white;

  &:hover {
    color: $white !important;
  }
}

.btn-primary {
  color: $white;

  &:hover {
    color: $white !important;
  }
}

.btn-light {
  color: $primary;

  &:hover {
    color: $primary !important;
  }
}